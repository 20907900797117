/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type QueryClient, keepPreviousData, useQuery } from '@tanstack/react-query';
import { defaultCompositeSearch } from '@vakantiesnl/services/src/search';
import { type Search } from '@vakantiesnl/types';

import { fetchZooverCompositeSearch, getZooverCompositeSearchQueryKey } from './useGetCompositeSearch';

function buildQueryArgsForSimilarAccos(
	accommodation: Search.CompactAccommodationItem,
): Search.ZooverCompositeSearchBody {
	const regionId = accommodation.parents.find((parent) => parent.destinationType === 'REGION')?.heliosId?.toString();

	return accommodation.chain?.has_promotion_zvr
		? {
				...defaultCompositeSearch,
				page_size: 9,
				filters: {
					...defaultCompositeSearch.filters,
					chains: [accommodation.chain.entity_id],
				},
				transport_type: accommodation.isBookable ? 'INCLUDING_FLIGHT' : 'OWN',
			}
		: {
				...defaultCompositeSearch,
				page_size: 9,
				filters: {
					...defaultCompositeSearch.filters,
					geo_filters: {
						region_ids: regionId ? [regionId] : [],
					},
					accommodationType: [accommodation.type],
				},
				transport_type: accommodation.isBookable ? 'INCLUDING_FLIGHT' : 'OWN',
			};
}

export function useGetSimilarAccommodations(accommodation: Search.CompactAccommodationItem) {
	const args = buildQueryArgsForSimilarAccos(accommodation);

	const query = useQuery({
		queryKey: getZooverCompositeSearchQueryKey(args),
		queryFn: () => fetchZooverCompositeSearch(args),
		placeholderData: keepPreviousData,
	});

	return query.data?.accommodations ?? [];
}

export async function prefetchSimilarAccommodations(
	queryClient: QueryClient,
	accommodation: Search.CompactAccommodationItem,
) {
	const args = buildQueryArgsForSimilarAccos(accommodation);

	try {
		return await queryClient.prefetchQuery({
			queryKey: getZooverCompositeSearchQueryKey(args),
			queryFn: () => fetchZooverCompositeSearch(args),
		});
	} catch {
		// Don't throw an error if this crashes, this data is not mandatory to load the page
		return undefined;
	}
}
